.PatientArchive {
  .PatientCard {
    &__disabled:first-child {
      margin-top: 48px;
    }
    .ant-card-body {
      display: flex;
      flex-direction: row;
      width: 100%;
      .CardContent {
        margin-left: 8px;
        margin-top: 4px;
        font-size: 110%;
        &-title {
          font-weight: bold;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &-description {
          opacity: 0.75;
        }
      }
    }
  }
  .Wrapper {
    h4 {
      &.Others {
        margin-top: 32px;
      }
      display: flex;
      flex-direction: column-reverse;
      font-variant: small-caps;
      padding-left: 2px;
      font-weight: bold;
      font-size: 20px;
      small {
        font-weight: normal !important;
        font-size: 14px;
      }
    }
    padding: 0px 12px;
    .ant-avatar {
      margin-right: 12px !important;
    }
    .ProfileHeader {
      padding: 0px;
      .ant-page-header-heading-left {
        flex-direction: column-reverse;
        align-items: flex-start;
        span {
          margin-right: 0px;
        }
      }
    }
    .ProfileButtons {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      gap: 32px;
      margin-bottom: 48px;
    }
    .ProfileButton__BigButton {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding-top: 13px;
      padding-bottom: 13px;
      text-align: center;
      width: 96px;
      &,
      * {
        cursor: pointer;
      }
      button {
        width: 64px !important;
        height: 64px !important;
        font-size: 32px;
        margin-bottom: 8px;
        padding-top: 16px;
      }
    }
  }
}

.ant-card-body {
  .Icon {
    .svgInline {
      svg {
        max-width: 48px;
        margin-right: 8px;
        max-height: 64px;
        * {
          fill: #0069b8;
        }
      }
    }
  }
}
