.DetailActivity {
  svg {
    max-width: 32px;
    max-height: 32px;
    margin-bottom: 8px;
    * {
      fill: #333;
    }
  }
  .ant-descriptions-item-label.DividerRow {
    column-span: all;
  }
  .ant-descriptions-item-content.DividerRow {
    display: none;
  }
}
.PatientView {
  .isDesktop & {
    margin-top: 16px;
  }
  .EnterActivity {
    position: absolute;
    right: 0px;
    margin-top: -32px;
    // opacity: 0.5;
    color: #a5a5a5;
    svg * {
      fill: #a5a5a5;
      color: #a5a5a5;
    }
  }
  .ActivityList {
    gap: 8px;
    .ant-steps-item-title {
      font-weight: bold;
      display: flex;
      flex-direction: column;
      line-height: 20px;
      .ant-steps-item-subtitle {
        margin-left: 0px;
        line-height: 16px;
      }
    }
    svg {
      max-width: 32px;
      max-height: 28px;
      margin-top: 16px;
      * {
        // fill: #a5a5a5;
        fill: #0069b8;
      }
    }
    .AltaMédica,
    .Fallecimiento,
    .TrasladoInterno,
    .TasladoBloqueo{
      svg {
        * {
          fill: #be3331;
        }
      }
    }
    .ant-steps-item-active {
      svg {
        * {
          fill: #0069b8;
        }
      }
    }
  }
  .ant-btn,
  button {
    // height: 48px !important;
    // width: 48px !important;
    // min-width: 48px !important;
  }
  .ContactButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    button {
      height: 48px !important;
      width: 48px !important;
      min-width: 48px !important;
      background: #0069b8;
    }
  }
  .DemographicInfo {
    ul {
      list-style: none; /* Remove default bullets */
      margin: 0px;
      padding: 0px;
      li {
        padding-left: 16px;
        line-height: 32px;
        font-size: 16px;
        &::before {
          content: ' '; /* Add content: \2022 is the CSS Code/unicode for a bullet */
          background: #0069b8;
          border-radius: 100%;
          color: #0069b8; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 12px; /* Also needed for space (tweak if needed) */
          height: 12px;
          line-height: 48px;
          margin-right: 8px;
          margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
        &.Egreso::before {
          background: #be3331;
          color: #be3331;
        }
      }
    }
  }
  // .PatientCard {
  .ant-row {
    margin: 16px 0px;
    &:first-child {
      margin-top: 8px;
    }
  }
  .CardContent {
    margin-left: 8px;
    margin-top: 4px;
    font-size: 110%;
    &-title {
      font-weight: bold;
    }
    &-description {
      opacity: 0.75;
    }
  }
  .ChangeRoom {
    height: 56px;
    border-radius: 16px;
  }
  .RegisterActivity {
    height: 56px;
    border-radius: 16px;
    background: #0069b8;
  }
  .Wrapper {
    padding: 0px 12px;
    .ant-avatar {
      margin-right: 12px !important;
    }
    .ProfileHeader {
      padding: 0px;
      .ant-page-header-heading-left {
        flex-direction: column-reverse;
        align-items: flex-start;
        span {
          margin-right: 0px;
        }
      }
    }
    .ProfileButtons {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      gap: 32px;
      margin-bottom: 48px;
    }
    .ProfileButton__BigButton {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding-top: 13px;
      padding-bottom: 13px;
      text-align: center;
      width: 96px;
      &,
      * {
        cursor: pointer;
      }
      button {
        width: 64px !important;
        height: 64px !important;
        font-size: 32px;
        margin-bottom: 8px;
        padding-top: 16px;
      }
    }
  }
}

.LiberarModal,
.ContactoModal {
  .ant-modal-close-x {
    svg {
      * {
        fill: #fff;
      }
    }
  }
}

.LiberarModal {
  .ant-modal-header {
    background: #be3331;
    * {
      color: #fff;
    }
  }
  .ant-btn-primary:not([disabled]) {
    background: #be3331;
    border-color: #be3331;
  }
  .ListReleaseEvents {
    .ListItemRadio {
      align-items: flex-start;
      span {
        &:first-child {
          // margin-top: 2px;
          margin-right: 4px;
        }
        &:last-child {
          display: flex;
          flex-direction: row;
          small {
            font-weight: normal;
          }
        }
      }
    }
    .svgInline--loaded {
      svg {
        * {
          fill: #be3331;
        }
        height: 24px;
        width: 24px;
        margin-right: 8px;
      }
    }
  }
}

.ContactoModal {
  .ant-modal-header {
    background: #0069b8;
    * {
      color: #fff;
    }
  }
  .ant-btn-primary:not([disabled]) {
    background: #0069b8;
    border-color: #0069b8;
  }
  .ListReleaseEvents {
    .ListItemRadio {
      align-items: flex-start;
      span {
        &:first-child {
          // margin-top: 2px;
          margin-right: 4px;
        }
        &:last-child {
          display: flex;
          flex-direction: row;
          small {
            font-weight: normal;
          }
        }
      }
    }
    .svgInline--loaded {
      svg {
        * {
          fill: #0069b8;
        }
        height: 24px;
        width: 24px;
        margin-right: 8px;
      }
    }
  }
}

span.ant-input-prefix {
  opacity: 0.55;
}

.ant-col {
  .Icon {
    .svgInline {
      svg {
        max-width: 53px;
        max-height: 64px;
        * {
          fill: #0069b8;
        }
      }
    }
  }
}


.ant-popconfirm {
  z-index: 16000;
}